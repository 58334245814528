<template>
  <div>
    <v-dialog
      v-model="isEditDiscount"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t("edit") }} - {{ $t("discount_amount") }}
        </v-card-title>
        <v-form
          ref="formEditDiscount"
          @submit.prevent="updateLimitDiscount"
        >
          <v-card-text>
            <v-text-field
              v-model="dataEditLocal.role_th"
              disabled
              outlined
              dense
              :label="$t('role')"
            ></v-text-field>
            <v-text-field
              v-model="dataEditLocal.role_shop_discount"
              autofocus
              outlined
              type="number"
              :rules="[required]"
              dense
              :label="$t('max_reduction')"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              type="submit"
            >
              {{ $t("save") }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-discount',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import limitDiscount from '@/api/systemSetting/limitDiscount'
import store from '@/store'

export default {
  model: {
    prop: 'isEditDiscount',
    event: 'update:is-edit-discount',
  },
  props: {
    isEditDiscount: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formEditDiscount = ref(null)
    const dataEditLocal = ref({})
    const loading = ref(false)

    watch(() => props.dataEdit, newVal => {
      dataEditLocal.value = JSON.parse(JSON.stringify(newVal))
    })

    const { limitDiscountUpdate } = limitDiscount

    const updateLimitDiscount = () => {
      const isFormValid = formEditDiscount.value.validate()
      if (!isFormValid) return
      loading.value = true
      limitDiscountUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-edit-discount', false)
        emit('onUpdate')
      })
    }

    return {
      loading,
      formEditDiscount,
      dataEditLocal,
      updateLimitDiscount,
      required,
    }
  },
}
</script>
<style lang="">

</style>
