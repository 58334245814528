import { onMounted, ref } from '@vue/composition-api'
import limitDiscount from '@/api/systemSetting/limitDiscount'
import { i18n } from '@/plugins/i18n'

export default function useLimitDiscount() {
  const dataTableList = ref([])
  const dataTableList2 = ref([])
  const tableColumns = ref([
    {
      text: '#', align: 'start', value: 'role_shop_id', width: 100,
    },
    { text: i18n.t('role'), value: 'role_th' },
    { text: i18n.t('max_reduction'), value: 'role_shop_discount', align: 'center' },
    { text: i18n.t('option'), value: 'actions', align: 'center' },
  ])
  const tableColumns2 = ref([
    {
      text: '#', align: 'start', value: 'id', width: 100,
    },
    { text: i18n.t('edit'), value: 'role_id' },
    { text: i18n.t('edited_by'), value: 'user_fullname' },
    { text: i18n.t('edit_when'), value: 'log_time' },
  ])
  const options = ref({})
  const loading = ref(false)
  const { limitDiscountList, limitDiscountLogList } = limitDiscount

  onMounted(() => {
    fetchDataTable()
  })
  const fetchDataTable = () => {
    loading.value = true
    limitDiscountList().then(res => {
      dataTableList.value = res
      options.value.page = -1
      loading.value = false
    })
    limitDiscountLogList().then(res => {
      options.value.page = -1
      dataTableList2.value = res
      loading.value = false
    })
  }

  return {
    tableColumns2,
    dataTableList2,
    options,
    loading,
    dataTableList,
    tableColumns,
    fetchDataTable,
  }
}
