var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EditLimitDiscount',{attrs:{"data-edit":_vm.dataEdit},on:{"onUpdate":_vm.fetchDataTable},model:{value:(_vm.isEditDiscount),callback:function ($$v) {_vm.isEditDiscount=$$v},expression:"isEditDiscount"}}),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Limited discount on user rights'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.dataTableList,"options":_vm.options,"loading":_vm.loading,"hide-default-footer":"","disable-sort":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.role_shop_id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.role_th",fn:function(ref){
var item = ref.item;
return [(_vm.$i18n.locale == 'th')?_c('span',[_vm._v(_vm._s(item.role_th))]):_vm._e(),(_vm.$i18n.locale == 'en')?_c('span',[_vm._v(_vm._s(item.role_en))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.dataEdit = item,_vm.isEditDiscount = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])])]}}],null,true)})],1),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('revision_history'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.tableColumns2,"items":_vm.dataTableList2,"options":_vm.options,"loading":_vm.loading,"hide-default-footer":"","disable-sort":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.log_time",fn:function(ref){
var item = ref.item;
return [_c('ConversDateTime',{attrs:{"datetime":item.log_time}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }