<template>
  <div>
    <EditLimitDiscount
      v-model="isEditDiscount"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <v-card>
      <v-card-title>
        {{ $t('Limited discount on user rights') }}
      </v-card-title>
      <v-data-table
        :headers="tableColumns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.role_shop_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.role_th`]="{ item }">
          <span v-if="$i18n.locale == 'th'">{{ item.role_th }}</span>
          <span v-if="$i18n.locale == 'en'">{{ item.role_en }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip

            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item,isEditDiscount = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-5">
      <v-card-title>
        {{ $t('revision_history') }}
      </v-card-title>
      <v-data-table
        :headers="tableColumns2"
        :items="dataTableList2"
        :options.sync="options"
        :loading="loading"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.log_time`]="{ item }">
          <ConversDateTime :datetime="item.log_time" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { mdiPencilOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import useLimitDiscount from './useLimitDiscount'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import EditLimitDiscount from './EditLimitDiscount.vue'

export default {
  components: {
    ConversDateTime,
    EditLimitDiscount,
  },
  setup() {
    const {
      options,
      loading, dataTableList, tableColumns, dataTableList2, tableColumns2, fetchDataTable,
    } = useLimitDiscount()

    const isEditDiscount = ref(false)
    const dataEdit = ref({})

    return {
      dataEdit,
      isEditDiscount,
      dataTableList2,
      tableColumns2,
      options,
      loading,
      dataTableList,
      tableColumns,
      fetchDataTable,
      icons: {
        mdiPencilOutline,
      },
    }
  },

}
</script>
